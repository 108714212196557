import { createActions } from 'redux-actions'


export const {
  getProvinceList,
  getProvinceListSuccess,
  getProvinceListFail,

  getUniversityList,
  getUniversityListSuccess,
  getUniversityListFail,

  getNationList,
  getNationListSuccess,
  getNationListFail,

  getOccupationTags,
  getOccupationTagsSuccess,
  getOccupationTagsFail,
} = createActions(
  'GET_PROVINCE_LIST',
  'GET_PROVINCE_LIST_SUCCESS',
  'GET_PROVINCE_LIST_FAIL',

  'GET_UNIVERSITY_LIST',
  'GET_UNIVERSITY_LIST_SUCCESS',
  'GET_UNIVERSITY_LIST_FAIL',

  'GET_NATION_LIST',
  'GET_NATION_LIST_SUCCESS',
  'GET_NATION_LIST_FAIL',

  'GET_OCCUPATION_TAGS',
  'GET_OCCUPATION_TAGS_SUCCESS',
  'GET_OCCUPATION_TAGS_FAIL',
)
