const navBarItems = [
  {
    name: 'mentor-list',
    requiredAuth: true,
    role: 'STUDENT'
  },
  {
    name: 'student-list',
    requiredAuth: true,
    role: 'MENTOR'
  },
  {
    name: 'matching-guide',
    requiredAuth: true
  },
]

export {
  navBarItems
}
