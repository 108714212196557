import { handleActions } from 'redux-actions'

import AuthProvider from '../../connectors/AuthProvider'

import {
  refreshAuthState,
} from '../actions/accountAction'


let defaultState = {
  authorized: AuthProvider.checkAuth()
}

const accountReducer = handleActions(
  {
    [refreshAuthState]: (state) => {
      return {
        ...state,
        authorized: AuthProvider.checkAuth()
      }
    },
  },
  defaultState
)

export default accountReducer