function getLocalToken() {
  return window.localStorage.getItem('access_token')
}

function getLocalRefreshToken() {
  return window.localStorage.getItem('refresh_token')
}

export {
  getLocalToken,
  getLocalRefreshToken
}
