import { get } from 'lodash'
import moment from 'moment'
import { sha256 } from 'js-sha256';
import { decode } from 'js-base64';

import { ResponseCode } from '../constants/constants'
import { getLocalRefreshToken } from '../utils/token';
import restConnector from './RestConnector'

const authProvider = {
  login:  async({ username, password }) => {
    const encrypted_password = sha256(password)
    try {
      const response = await restConnector.post(`/auth/login`, {
        username,
        encrypted_password,
      })
      const result = get(response, 'data.result', ResponseCode.ERROR_PARAMS)
      if (result === ResponseCode.SUCCESS) {
        const reply = get(response, 'data.reply', {})
        if (reply?.credentials && reply?.credentials?.access_token) {
          const access_token = reply?.credentials?.access_token;
          const [, payloadToken] = access_token.split('.')
          const payloadStr = decode(payloadToken)
          window.localStorage.setItem('username', username)
          if (payloadStr) {
            window.localStorage.setItem('user_profile', payloadStr)
          }
          restConnector.setToken(reply?.credentials?.access_token)
          window.localStorage.setItem('refresh_token', reply?.credentials?.refresh_token)
        }
        return true
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  },
  refreshToken: () => {
    return restConnector.post(`/auth/refresh_access_token`, {
      refresh_token: getLocalRefreshToken(),
    })
  },
  checkAuth: () => {
    return !!window.localStorage.getItem('user_profile')
  },
  getUserProfile: () => {
    const userProfileStr = window.localStorage.getItem('user_profile');
    if (userProfileStr) {
      return JSON.parse(userProfileStr)
    }
  },
  logout: () => {
    // clear token in local storage
    window.localStorage.removeItem('user_profile');
    restConnector.setToken();
    window.localStorage.removeItem('refresh_token')
    window.location.href = '/login'
  },
  register: async (values) => {
    const { data } = await restConnector.post(`/sponsor/register`, values)
    return data
  },
  changePassword: async ({ username, oldPassword, newPassword }) => {
    const response = await restConnector.post(`/auth/change_password`, {
        username,
        encrypted_password: sha256(oldPassword), // hash password using sha256
        encrypted_new_password: sha256(newPassword)
      })
    const result = get(response, 'data.result', ResponseCode.ERROR_PARAMS)

    return result
  },
}

export default authProvider
