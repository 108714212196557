import React, { useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import { withFormik, Field, Form } from 'formik'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'
import swal from 'sweetalert'

import AccountCircle from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { refreshAuthState } from '../../redux/actions/accountAction'
import AuthProvider from '../../connectors/AuthProvider'
import TextField from '../../components/TextField'
import validationSchema from './validationSchema'
import { ResponseCode } from '../../constants/constants'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 100,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 50,
      height: 'unset',
      alignItems: 'flex-start',
    }
  },
  container: {
    width: 400,
    padding: 30,
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      width: 350,
      padding: 10,
    }
  },
  header: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 20
  },
  error: {
    fontSize: 10,
    textAlign: 'center',
    color: theme.palette.error.main,
  },
  noAccountContainer: {
    textAlign: 'center',
    marginTop: 30,
  },
  prefix: {
    color: theme.palette.white.dark,
  },
  createAccount: {
    marginLeft: 10,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    color: theme.palette.primary.dark
  }
}))

const ChangePassword = (props) => {
  const [showPassword, toggleShowPassword] = useState(false)
  const { handleSubmit, errors, } = props
  const classes = useStyles()
  const { t } = useTranslation('login')

  const changePasswordError = get(errors, 'general', null)

  return (
    <div className={classes.root}>
      <Paper elevation={5} className={classes.container}>
        <Form onSubmit={handleSubmit}>
          <div className={classes.header}>{t('change-password')}</div>
          {changePasswordError && <div className={classes.error}>{t(changePasswordError)}</div>}
          <Field name={`username`} label={t('Username/Email')} required fullWidth component={TextField}
            InputProps={{
              startAdornment:
                <InputAdornment position="start">
                  <IconButton
                    aria-label="username"
                  >
                    <AccountCircle/>
                  </IconButton>
                </InputAdornment>
            }}
          />
          <Field name={`oldPassword`} label={t('Password')} required fullWidth component={TextField}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              startAdornment:
                <InputAdornment position="start">
                  <IconButton
                    aria-label="password"
                  >
                    <LockIcon/>
                  </IconButton>
                </InputAdornment>,
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => toggleShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
            }}
          />
          <Field name={`newPassword`} label={t('New password')} required fullWidth component={TextField}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              startAdornment:
                <InputAdornment position="start">
                  <IconButton
                    aria-label="new-password"
                  >
                    <LockIcon />
                  </IconButton>
                </InputAdornment>,
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => toggleShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
            }}
          />
          <Button 
            color="primary" type="submit" variant="contained" fullWidth style={{ marginTop: 20 }}>
            {t('change-password')}
          </Button>
        </Form>

        <div style={{ marginTop: 20, lineHeight: 1.25 }}>
          {t(`technical support contact`)}
          <a href="mailto:vs.sponsorship@vietseeds.org">vs.sponsorship@vietseeds.org</a>
        </div>
      </Paper>
    </div>
  )
}

const ChangePasswordWithFormik = withFormik({
  initialValues: {
    username: localStorage.getItem('username') || '',
    oldPassword: '',
    newPassword: ''
  },

  validationSchema: validationSchema,

  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    setSubmitting(true)

    const { username, oldPassword, newPassword } = values
    // Call API to check valid username and password
    const result = await AuthProvider.changePassword({ username, oldPassword, newPassword })
    
    if (result !== ResponseCode.SUCCESS) {
      setFieldError('general', 'Sai tên đăng nhập hoặc mật khẩu cũ')
    } else {
      swal({
        title: 'Success',
        text: '',
        icon: 'success',
      }).then(() => {
        window.location = '/'
      })
    }
        
    setSubmitting(false)
  }
})(ChangePassword)

const mapStateToProps = ({ account }) => {
  return {
    authorized: account.authorized
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refreshAuthStateHandler: refreshAuthState,
}, dispatch)


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChangePasswordWithFormik))
