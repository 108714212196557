import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import { withFormik, Field, Form } from 'formik'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'

import AccountCircle from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { navBarItems } from '../../components/Layout/constants';

import { refreshAuthState } from '../../redux/actions/accountAction'
import Link from '../../components/Link'
import AuthProvider from '../../connectors/AuthProvider'
import TextField from '../../components/TextField'
import { isEmpty } from '../../utils/typeof';
import validationSchema from './validationSchema'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 100,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 50,
      height: 'unset',
      alignItems: 'flex-start',
    }
  },
  container: {
    width: 400,
    padding: 30,
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      width: 350,
      padding: 10,
    }
  },
  header: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 20
  },
  error: {
    fontSize: 10,
    textAlign: 'center',
    color: theme.palette.error.main,
  },
  noAccountContainer: {
    textAlign: 'center',
    marginTop: 30,
  },
  prefix: {
    color: theme.palette.white.dark,
  },
  createAccount: {
    marginLeft: 10,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    color: theme.palette.primary.dark
  }
}))

const Login = (props) => {
  const [show_invitation_code, toggleShowInvitationCode] = useState(false)
  const { handleSubmit, errors, setFieldValue, values } = props
  const classes = useStyles()
  const { t } = useTranslation('login')

  const loginError = get(errors, 'general', null)

  useEffect(() => {
    if (values.username) {
      setFieldValue('username', values.username.trim())
    }
    if (values.password) {
      setFieldValue('password', values.password.trim())
    }
  }, [values.username, values.password])

  return (
    <div className={classes.root}>
      <Paper elevation={5} className={classes.container}>
        <Form onSubmit={handleSubmit}>
          <div className={classes.header}>{t('login')}</div>
          {loginError && <div className={classes.error}>{t(loginError)}</div>}
          <Field name={`username`} label={t('Username')} required fullWidth component={TextField}
            InputProps={{
              startAdornment:
                <InputAdornment position="start">
                  <IconButton
                    aria-label="username"
                  >
                    <AccountCircle/>
                  </IconButton>
                </InputAdornment>
            }}
          />
          <Field name={`password`} label={t('Password')} required fullWidth component={TextField}
            type={show_invitation_code ? 'text' : 'password'}
            InputProps={{
              startAdornment:
                <InputAdornment position="start">
                  <IconButton
                    aria-label="password"
                  >
                    <LockIcon/>
                  </IconButton>
                </InputAdornment>,
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => toggleShowInvitationCode(!show_invitation_code)}
                  >
                    {show_invitation_code ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
            }}
          />
          <Button color="primary" type="submit" variant="contained" fullWidth style={{ marginTop: 20 }}>
            {t('login')}
          </Button>
        </Form>


        {/*<div className={classes.noAccountContainer}>*/}
        {/*  <span className={classes.prefix}>{t('not having an account')}?</span>*/}
        {/*    <Link className={classes.createAccount} to='/register'>*/}
        {/*      {t('create account')}*/}
        {/*    </Link>*/}
        {/*</div>*/}

        <div style={{ marginTop: 20, lineHeight: 1.25 }}>
          {t(`technical support contact`)}
          <a href="mailto:vs.sponsorship@vietseeds.org">vs.sponsorship@vietseeds.org</a>
        </div>
      </Paper>
    </div>
  )
}

const LoginWithFormik = withFormik({
  initialValues: {
    username: '',
    password: '',
  },

  validationSchema: validationSchema,

  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    setSubmitting(true)

    const { username, password } = values
    // Call API to check valid username and password
    const loginResponse = await AuthProvider.login({ username, password })
    const { location, history, refreshAuthStateHandler } = props

    let from = location?.state?.from;
    if (loginResponse) {
      setTimeout(() => {
        refreshAuthStateHandler()
        const userProfile = AuthProvider.getUserProfile();
        const roles = userProfile?.roles || [];

        if (from) {
          const isValidateInRule = navBarItems.some(item => {
            const { requiredAuth, name, role } = item
            if (requiredAuth && !AuthProvider.checkAuth()) {
              return false
            }

            if (!isEmpty(role) && !roles?.includes(role)) {
              return false
            }

            return from?.pathname?.includes(name);
          })
          if (isValidateInRule) {
            setTimeout(() => {
              if (isValidateInRule && history?.replace && from) {
                history.replace(from);
              }
            }, 300)
            return;
          }
        }

        const firstNavBarItems = navBarItems.find(item => {
          const { requiredAuth, role } = item
          if (requiredAuth && !AuthProvider.checkAuth()) {
            return false
          }

          return !(!isEmpty(role) && !roles?.includes(role));
        })
        setTimeout(() => {
          if (history?.replace) {
            if (firstNavBarItems) {
              history.replace(`/${firstNavBarItems?.name}`);
            } else {
              history.replace('/');
            }
          }
        }, 300)
      }, 100)
    } else {
      setFieldError('general', 'wrong username or password')
    }

    setSubmitting(false)
  }
})(Login)

const mapStateToProps = ({ account }) => {
  return {
    authorized: account.authorized
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refreshAuthStateHandler: refreshAuthState,
}, dispatch)


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginWithFormik))
