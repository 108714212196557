import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';

const NotFound = props => {
  const { authorized, location } = props

  if (authorized) {
    return (
      <Redirect to='/student-list' />
    )
  }
  return (
    <Redirect to={{
        pathname: '/login',
        state: { from: location }
      }}
    />
  )
}

const mapStateToProps = ({ account }) => {
  return {
    authorized: account.authorized,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch)


export default connect(mapStateToProps, mapDispatchToProps)(NotFound)