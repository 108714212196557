import { lazy } from 'react';
import { retry } from './utils/file';
import Login from './pages/Login';
import ChangePassword from './pages/ChangePassword/index'

const MentorList = lazy(() => retry(() => import('./pages/MentorList')));
const MentorMatchingList = lazy(() => retry(() => import('./pages/MentorMatchingList')));
const StudentList = lazy(() => retry(() => import('./pages/StudentList')));
const MyStudentList = lazy(() => retry(() => import('./pages/MyStudentList')));
const StudentProfile = lazy(() => retry(() => import('./pages/StudentProfile')));
const DonationGuide = lazy(() => retry(() => import('./pages/DonationGuide')));
const DonateForm = lazy(() => retry(() => import('./pages/DonateForm')));
const Register = lazy(() => retry(() => import('./pages/Register')));
const MentorProfile = lazy(() => retry(() => import('./pages/MentorProfile')));
const MatchingMentorForm = lazy(() => retry(() => import('./pages/MatchingMentorForm')));
const MatchingGuide = lazy(() => retry(() => import('./pages/MatchingGuide')));

const AppRoutes = [
  { path: "/mentor-list", component: MentorList, exact: true, requiredAuth: true },
  { path: "/mentor/:id", component: MentorProfile, exact: true, requiredAuth: true },
  { path: "/student-list", component: StudentList, exact: true, requiredAuth: true },
  { path: "/student/:id", component: StudentProfile, exact: true, requiredAuth: true },
  { path: "/matching-mentor-list", component: MentorMatchingList, exact: true, requiredAuth: true },
  { path: "/matching-guide", component: MatchingGuide, exact: true, },
  // { path: "/my-student-list", component: MyStudentList, exact: true, requiredAuth: true },
  // { path: "/donate", component: DonateForm, exact: true, requiredAuth: true },
  { path: "/matching-mentor", component: MatchingMentorForm, exact: true, requiredAuth: true },
  { path: "/login", component: Login, exact: true, requiredUnAuth: true },
  { path: "/register", component: Register, exact: true, requiredUnAuth: true },
  { path: "/change-password", component: ChangePassword, exact: true, requiredAuth: true },
]

export default AppRoutes
