import React from "react";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Route,
  Redirect, useLocation, useHistory,
} from "react-router-dom";
import { navBarItems } from './components/Layout/constants';
import AuthProvider from './connectors/AuthProvider';
import { isEmpty, isObject } from './utils/typeof';

const RequiredUnAuthRoute = ({ children, ...rest }) => {
  const { authorized } = rest
  const location = useLocation()
  let from = location?.state?.from;
  const userProfile = AuthProvider.getUserProfile();
  const roles = userProfile?.roles || [];
  let urlRedirect = '/'
  if (authorized) {
    let isValidateInRule = false
    if (from) {
      isValidateInRule = navBarItems.some(item => {
        const { requiredAuth, name, role } = item
        if (requiredAuth && !AuthProvider.checkAuth()) {
          return false
        }

        if (!isEmpty(role) && !roles?.includes(role)) {
          return false
        }

        return from?.pathname?.includes(name);
      })
    }

    if (isValidateInRule) {
      urlRedirect = from;
    } else {
      const firstNavBarItems = navBarItems.find(item => {
        const { requiredAuth, role } = item
        if (requiredAuth && !AuthProvider.checkAuth()) {
          return false
        }

        return !(!isEmpty(role) && !roles?.includes(role));
      })

      if (firstNavBarItems) {
        urlRedirect = `/${firstNavBarItems?.name}`;
      } else {
        urlRedirect = '/';
      }
    }
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        authorized ? (
          <Redirect
            to={isObject(urlRedirect) ? urlRedirect : { pathname: urlRedirect, }}
          />
        ) : (
          children
        )
      }
    />
  );
}

const mapStateToProps = ({ account }) => {
  return {
    authorized: account.authorized,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch)


export default connect(mapStateToProps, mapDispatchToProps)(RequiredUnAuthRoute)
