import { MenuItem, Menu, Avatar, ListItemIcon } from '@material-ui/core';
import { ExpandMore, ExpandLess, Settings, ExitToApp, Group, Lock } from '@material-ui/icons';
import React from 'react';
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { useTranslation } from "react-i18next"
import { getNameByDisplayName } from '../../utils/string';

import Logo from './Logo'
import LanguageSelector from './LanguageSelector'
import NavBarItems from './NavBarItems'

import AuthProvider from '../../connectors/AuthProvider'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  leftSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  centerSection: {
    flexGrow: 1,
  },
  rightSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  mainContainer: {
  },
  rootToolbar: (styles) => {
    return ({
      ...styles,
      width: '100%',
      maxWidth: '1256px',
      marginLeft: 'auto',
      marginRight: 'auto'
    });
  }
}));

const DesktopLayout = (props) => {
  const { t, i18n } = useTranslation('layout');
  const history = useHistory();
  const classes = useStyles()
  const { children } = props

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const userProfile = AuthProvider.getUserProfile();
  const roles = userProfile?.roles || []
  const isStudent = roles?.includes('STUDENT')
  const isMentor = roles?.includes('MENTOR')
  const isLogin = AuthProvider.checkAuth();

  const goToMatchingMentorList = () => {
    history.push('/matching-mentor-list')
  }

  return (
    <div className={classes.root}>
      <AppBar position="sticky" color="inherit">
        <Toolbar classes={{ root: classes.rootToolbar }}>
          <div className={classes.leftSection}>
            <Logo href='/student-list'/>
            <LanguageSelector style={{ marginLeft: 50 }}/>
          </div>
          <div className={classes.centerSection}>

          </div>
          <div className={classes.rightSection}>
            <NavBarItems/>
            {
              isLogin &&
              (
                <>
                  <Button
                    aria-controls="basic-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                  >
                    {t('hello')}, {getNameByDisplayName(userProfile?.display_name)}
                    {open ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                  >
                    {
                      isStudent &&
                      (<MenuItem onClick={goToMatchingMentorList}>
                        <ListItemIcon style={{ minWidth: 36 }}>
                          <Group fontSize="small" />
                        </ListItemIcon>
                        Quản lý matching
                      </MenuItem>)
                    }
                    <MenuItem onClick={() => history.push('/change-password')}>
                      <ListItemIcon style={{ minWidth: 36 }}>
                        <Lock fontSize="small" />
                      </ListItemIcon>
                      Đổi mật khẩu
                    </MenuItem>
                    <MenuItem onClick={AuthProvider.logout}>
                      <ListItemIcon style={{ minWidth: 36 }}>
                        <ExitToApp fontSize="small" />
                      </ListItemIcon>
                      Đăng xuất
                    </MenuItem>
                  </Menu>
                </>
              )
            }
            {
              !isLogin &&
              (
                <React.Fragment>
                  {/*<Button color="inherit" href={`/register`}>{t('register')}</Button>*/}
                  <Button color="inherit" href={`/login`}>{t('log-in')}</Button>
                </React.Fragment>
              )
            }
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.mainContainer}>
        {children}
      </div>
    </div>
  );
}

export default DesktopLayout
