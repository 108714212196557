import { all } from 'redux-saga/effects';

import {
  getProvinceListWatcher,
  getUniversityListWatcher,
  getNationListWatcher,
  getOccupationTagsWatcher,
} from './generalSaga'

export default function* rootSaga() {
  yield all([
    // general
    getProvinceListWatcher(),
    getUniversityListWatcher(),
    getNationListWatcher(),
    getOccupationTagsWatcher(),
  ]);
}
