import React, { useEffect, Suspense } from 'react';
import ScrollToTop from 'react-scroll-to-top';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import Layout from './components/Layout/index'
import Loading from './components/Loading';
import AppRoutes from './AppRoutes'
import RequiredAuthRoute from './RequiredAuthRoute'
import RequiredUnAuthRoute from './RequiredUnAuthRoute'
import NotFound from './pages/NotFound/index'

import { getProvinceList, getUniversityList, getNationList, getOccupationTags } from './redux/actions/generalAction'

const theme = createMuiTheme({
  palette: {
    primary: {
      lighter: '#eef6e9',
      light: '#6fbf73',
      main: '#4caf50',
      littleDark: '#5da62b',
      dark: '#357a38',
      contrastText: '#fff',
    },
    secondary: {
      light: '#f2a18b',
      main: '#ef8a6f',
      dark: '#a7604d',
      contrastText: '#000',
    },
    white: {
      dark: '#464646',
      main: '#fff',
    },
    error: {
      main: '#f0443c',
    }
  }
},)


const App = (props) => {
  const {
    getProvinceListHandler,
    getUniversityListHandler,
    getNationListHandler,
    getOccupationTagsHandler
  } = props

  useEffect(() => {
    const originalSetItem = window.localStorage.setItem;

    window.localStorage.setItem = function(key, value) {
      const event = new Event('store-changed');

      event.value = value; // Optional..
      event.key = key; // Optional..

      document.dispatchEvent(event);

      originalSetItem.apply(this, arguments);
    };

    getOccupationTagsHandler()
    getProvinceListHandler()
    getUniversityListHandler()
    getNationListHandler()
  }, [])
  return (
    <MuiThemeProvider theme={theme}>
      <ScrollToTop
        smooth
        style={{
          padding: '0',
          boxShadow: '0 2px 7px 0 rgba(132, 128, 177, 0.2)',
          borderRadius: '50%',
          backgroundColor: '#afd53e'
        }}
        component={
          <svg
            fill="#fff"
            viewBox="0 0 320 512"
            width="28px"
            height="28px"
          >
            <path d="M168.5 164.2l148 146.8c4.7 4.7 4.7 12.3 0 17l-19.8 19.8c-4.7 4.7-12.3 4.7-17 0L160 229.3 40.3 347.8c-4.7 4.7-12.3 4.7-17 0L3.5 328c-4.7-4.7-4.7-12.3 0-17l148-146.8c4.7-4.7 12.3-4.7 17 0z" />
          </svg>
        }
      />
      <Router>
        <Suspense fallback={<Loading />}>
          <Switch>
            {
              AppRoutes.map(route => {
                const { path, exact, requiredAuth, requiredUnAuth, component: Component, ...other } = route

                let RouteComponent = Route
                if (requiredAuth) RouteComponent = RequiredAuthRoute
                if (requiredUnAuth) RouteComponent = RequiredUnAuthRoute

                return (
                  <RouteComponent key={path} path={path} exact={exact} {...other}>
                    <Layout>
                      <Component/>
                    </Layout>
                  </RouteComponent>
                )
              })
            }
            <Route component={NotFound}/>
          </Switch>
        </Suspense>
      </Router>
    </MuiThemeProvider>
  );
}

const mapStateToProps = ({ account }) => {
  return {
    authorized: account.authorized,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getProvinceListHandler: getProvinceList,
  getUniversityListHandler: getUniversityList,
  getNationListHandler: getNationList,
  getOccupationTagsHandler: getOccupationTags,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(App);
