import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon'
import { Img } from 'react-image'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import RestConnector from '../../connectors/RestConnector'

import { defaultLanguage } from '../../constants/constants'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import VieFlag from '../../static/flags/vietnam.png'
import EngFlag from '../../static/flags/united.png'

const useStyles = makeStyles((theme) => ({
  root: {

  },
  logo: {
    height: 50
  },
  imgIcon: {
    width: '100%',
    height: '100%'
  }
}));

const languageChoices = {
  vi: {
    label: 'VIE',
    flag: VieFlag,
  },
  en: {
    label: 'ENG',
    flag: EngFlag,
  }
}

const LanguageSelector = (props) => {
  const classes = useStyles()
  const [t, i18n] = useTranslation('layout');

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const setLanguage = (lg) => {
    if (lg in languageChoices) {
      i18n.changeLanguage(lg)
      window.localStorage.setItem('i18nextLng', lg)
      RestConnector.setLanguageHeader(lg)
    }
  }

  const onSelectLanguage = (e, lg) => {
    setLanguage(lg)
    handleClose(e)
  }

  // handle setLocation by link redirect from branding website
  const location = useLocation()
  const query = new URLSearchParams(location.search);
  const lg = query.get('lg');
  setLanguage(lg)

  let currentLanguage = i18n.language
  if (!(currentLanguage in languageChoices)) {
    currentLanguage = defaultLanguage
  }
  const { flag: FlagIcon } = languageChoices[currentLanguage]
  const { ...others } = props

  return (
    <div className={classes.root} {...others}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        startIcon={<Icon> <Img src={FlagIcon} className={classes.imgIcon} alt='flag'/></Icon>}
        endIcon={open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
      >
        {languageChoices[currentLanguage].label}
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {
                    Object.keys(languageChoices).map(key => {
                      const item = languageChoices[key]
                      const { label, flag: FlagChoiceIcon } = item
                      return (
                        <MenuItem key={key} name={key} onClick={(e) => onSelectLanguage(e, key)}>
                          <ListItemIcon>
                            <Img src={FlagChoiceIcon} width={20} height={20} alt='flag'/>
                          </ListItemIcon>
                          <Typography variant="inherit">{label}</Typography>
                        </MenuItem>
                      )
                    })
                  }
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

export default LanguageSelector
