import { isString } from './typeof';

export function classList(...classes) {
  return classes
    .filter(item => !!item)
    .join(' ');
}

export function jsUcfirst(string) {
  if (isString(string)) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}

export function getNameByDisplayName(fullName) {
  if (fullName) {
    const nameArr = fullName.split(' ')
    if (nameArr?.length >= 4) {
      return `${nameArr[nameArr?.length - 2]} ${nameArr[nameArr?.length - 1]}`
    }
    return nameArr[nameArr?.length - 1]
  }
  return ''
}
