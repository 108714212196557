import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

// en
import login_en from './en/login.json'
import layout_en from './en/layout.json'
import student_list_en from './en/student_list.json'
import student_profile_en from './en/student_profile.json'
import mentor_list_en from './en/mentor_list.json'
import mentor_profile_en from './en/mentor_profile.json'
import register_en from './en/register.json'
import matching_mentor_form_en from './en/matching_mentor_form.json'
import donate_form_en from './en/donate_form.json'
import donation_guide_en from './en/donation_guide.json'
import mentor_matching_list_en from './en/mentor_matching_list.json'
import yup_en from './en/yup.json'

// vi
import login_vi from './vi/login.json'
import layout_vi from './vi/layout.json'
import student_list_vi from './vi/student_list.json'
import student_profile_vi from './vi/student_profile.json'
import mentor_list_vi from './vi/mentor_list.json'
import mentor_profile_vi from './vi/mentor_profile.json'
import register_vi from './vi/register.json'
import matching_mentor_form_vi from './vi/matching_mentor_form.json'
import donate_form_vi from './vi/donate_form.json'
import donation_guide_vi from './vi/donation_guide.json'
import mentor_matching_list_vi from './vi/mentor_matching_list.json'
import yup_vi from './vi/yup.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: window.localStorage.getItem('i18nextLng') || "vi",

    fallbackLng: "vi", // use en if detected lng is not available

    saveMissing: true, // send not translated keys to endpoint

    keySeparator: false,
    // keySeparator: '.', // use '.' as key separator in json object's key

    interpolation: {
      escapeValue: false // react already safes from xss
    },

    resources: {
      en: {
        login: login_en,
        register: register_en,
        layout: layout_en,
        student_list: student_list_en,
        student_profile: student_profile_en,
        mentor_list: mentor_list_en,
        mentor_profile: mentor_profile_en,
        donate_form: donate_form_en,
        matching_mentor_form: matching_mentor_form_en,
        donation_guide: donation_guide_en,
        mentor_matching_list: mentor_matching_list_en,
        yup: yup_en,
      },
      vi: {
        login: login_vi,
        register: register_vi,
        layout: layout_vi,
        student_list: student_list_vi,
        student_profile: student_profile_vi,
        mentor_list: mentor_list_vi,
        mentor_profile: mentor_profile_vi,
        donate_form: donate_form_vi,
        matching_mentor_form: matching_mentor_form_vi,
        donation_guide: donation_guide_vi,
        mentor_matching_list: mentor_matching_list_vi,
        yup: yup_vi,
      }
    }
  });
