import * as Yup from 'yup';

const REQUIRE_MESSAGE = 'required field'

Yup.addMethod(Yup.string, 'length', function (args) {
  const { message = 'length limit exceeded', limit = 1024 } = args
  return this.test('string-length-limit', message, function (value) {
    const { path, createError } = this;
    return !value || value.length <= limit || createError({ path, message });
  });
})

const loginSchema = Yup.object().shape({
  username: Yup.string().required(REQUIRE_MESSAGE),
  oldPassword: Yup.string().required(REQUIRE_MESSAGE),
  newPassword: Yup.string().required(REQUIRE_MESSAGE),
});

export default loginSchema
