import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import LogoHorizontal from '../../static/images/logo-hor.png'

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 50
  },
  logoWrapper: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'item'
  }
}));

const Logo = (props) => {
  const classes = useStyles()

  return (
    <a className={classes.logoWrapper} href='/student-list'>
      <img src={LogoHorizontal} className={classes.logo} alt='logo-horizontal'/>
    </a>
  )
}

export default Logo
