const colors = {
  primaryDark: '#357a38',
  primaryMain: '#008640',
  primaryLight: '#6fbf73',
  primaryContrastText: '#fff',

  secondaryLight: '#f2a18b',
  secondaryMain: '#ef8a6f',
  secondaryDark: '#a7604d',
  secondaryContrastText: '#000',
  
  error: '#f0443c',
}

export default colors