import React, { useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { refreshAuthState } from '../../redux/actions/accountAction';

import DesktopLayout from './DesktopLayout'
import MobileLayout from './MobileLayout'

const Layout = (props) => {
  const isMobileLayout = useMediaQuery('(max-width:800px)');
  const { children, refreshAuthStateHandler } = props

  useEffect(() => {
    window.addEventListener('store-changed', () => {
      refreshAuthStateHandler()
    });

    window.addEventListener('storage', () => {
      refreshAuthStateHandler()
    });
  }, [])

  if (isMobileLayout) {
    return <MobileLayout>
      {children}
    </MobileLayout>
  } else {
    return <DesktopLayout>
      {children}
    </DesktopLayout>
  }
}

const mapStateToProps = ({ account }) => {
  return {
    authorized: account.authorized,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  refreshAuthStateHandler: refreshAuthState,
}, dispatch)


export default connect(mapStateToProps, mapDispatchToProps)(Layout)
