import { handleActions } from 'redux-actions'

import {
  getProvinceList,
  getProvinceListSuccess,
  getProvinceListFail,

  getUniversityList,
  getUniversityListSuccess,
  getUniversityListFail,

  getNationList,
  getNationListSuccess,
  getNationListFail, getOccupationTags, getOccupationTagsSuccess, getOccupationTagsFail,
} from '../actions/generalAction'

let defaultState = {
  isGettingProvinceList: false,
  provinceList: [],

  isGettingUniversityList: false,
  universityList: [],

  isGettingNationList: false,
  nationList: [],

  isGettingOccupationTags: false,
  occupationTags: [],
}

const generalReducer = handleActions(
  {
    [getProvinceList]: (state) => {
      return {
        ...state,
        isGettingProvinceList: true,
      }
    },
    [getProvinceListSuccess]: (state, { payload: provinceList }) => {
      return {
        ...state,
        provinceList,
        isGettingProvinceList: false,
      }
    },
    [getProvinceListFail]: (state, { payload: { error } }) => {
      return {
        ...state,
        error,
        isGettingProvinceList: false
      }
    },
    [getUniversityList]: (state) => {
      return {
        ...state,
        isGettingUniversityList: true,
      }
    },
    [getUniversityListSuccess]: (state, { payload: universityList }) => {
      return {
        ...state,
        universityList,
        isGettingUniversityList: false,
      }
    },
    [getUniversityListFail]: (state, { payload: { error } }) => {
      return {
        ...state,
        error,
        isGettingUniversityList: false
      }
    },
    [getNationList]: (state) => {
      return {
        ...state,
        isGettingNationList: true,
      }
    },
    [getNationListSuccess]: (state, { payload: nationList }) => {
      return {
        ...state,
        nationList,
        isGettingNationList: false,
      }
    },
    [getNationListFail]: (state, { payload: { error } }) => {
      return {
        ...state,
        error,
        isGettingNationList: false
      }
    },
    [getOccupationTags]: (state) => {
      return {
        ...state,
        isGettingOccupationTags: true,
      }
    },
    [getOccupationTagsSuccess]: (state, { payload: occupationTags }) => {
      return {
        ...state,
        occupationTags,
        isGettingOccupationTags: false,
      }
    },
    [getOccupationTagsFail]: (state, { payload: { error } }) => {
      return {
        ...state,
        error,
        isGettingOccupationTags: false
      }
    },
  },
  defaultState
)

export default generalReducer
