import React from 'react'
import { ErrorMessage } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import FormHelperText from "@material-ui/core/FormHelperText";
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'

import colors from '../constants/colors'

const ValidationTextField = withStyles({
  root: props => ({
    width: props.fullWidth && '100% !important',
    marginTop: 10,
    marginBottom: 10,
    "& label": {
      lineHeight: 1.5,
      width: '95%'
    },
    "& input:valid + fieldset": {
      borderColor: colors.primaryMain,
      borderWidth: 2
    },
    "& input:invalid + fieldset": {
      borderColor: colors.secondaryMain,
      borderWidth: 2
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important" // override inline-style
    }
  })
})(TextField);

const styles = (theme) => ({
  error: {
    margin: 0,
  },
  helperText: {
    margin: 0,
  },
})

const Input = (props) => {
  const { classes, field = {}, form, label, required, type, variant, helperText, fullWidth, ...others } = props
  const { t: tYup } = useTranslation('yup')
  
  return (
    <React.Fragment>
      <ValidationTextField
        label={label}
        required={required}
        type={type}
        variant={variant}
        {...field}
        {...others}
        fullWidth={fullWidth}
      />
      <ErrorMessage name={get(field, 'name', '')} 
        render={(msg) => 
          <FormHelperText error className={classes.error}>
            {tYup(msg)}
          </FormHelperText>
        }
      />
      {helperText && <FormHelperText className={classes.helperText}>{helperText}</FormHelperText> }
    </React.Fragment>
  )
}

export default withStyles(styles)(Input)
